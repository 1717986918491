<template>
  <v-dialog v-model="show" fullscreen>
    <v-card>
      <v-row no-gutters>
        <v-btn depressed class="ml-auto mr-4 mt-4" icon @click="show = false">
          <v-icon dark>mdi-close-thick</v-icon>
        </v-btn>
      </v-row>
      <risk-matrix :isFullScreenProp="true" :matrixDataProp="matrixData" :projectProp="project" :riskListProp="riskList"></risk-matrix>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'FullScreenMatrix',
  components: {
    RiskMatrix: () => import('../RiskMatrix.vue'),
  },
  data: () => ({
    show: false,
    matrixData: null,
    project: {},
    riskList: [],
  }),
  methods: {
    showFullScreen(_matrixData, _project, _riskList) {
      this.show = true;
      this.matrixData = _matrixData;
      this.project = _project;
      this.riskList = _riskList;
    },
  },
};
</script>

<style>
</style>