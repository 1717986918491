<template>
  <v-dialog v-model="show" v-if="squareData !== null" fullscreen>
    <v-card>
      <v-row no-gutters align="center">
        <v-card-title>{{ $t('PageTitles.SquareMatrix') }}</v-card-title>
        <v-btn depressed class="ml-auto mr-4" icon @click="show = false">
          <v-icon dark>mdi-close-thick</v-icon>
        </v-btn>
      </v-row>
      <v-card-text>
        <v-row no-gutters>
          <v-col cols="3">
            <p class="">{{ $util.convertConsequenceToText(squareData.probability) }} {{ squareData.probability }}</p>
          </v-col>
          <v-col cols="9">
            <div :class="GetClass(squareData.matrixItemLevel)" class="aRedBox fill-height pa-0">
              <v-row no-gutters>
                <v-col cols="12" v-for="(risks, k) in squareData.risks" :key="k" class="pa-0 pb-1">
                  <v-btn
                    style="border-bottom: 1px solid primary; height: 26px"
                    color="black"
                    class="ma-0 px-2"
                    plain
                    :to="{ name: 'RiskVersionDetails', params: { historyId: risks.id } }"
                  >
                    {{ writeName(risks) }}
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-col>
          <v-col cols="3" class="d-flex flex-colum">
            <v-row no-gutters>
              <v-col cols="12" class="d-flex justify-start">
                {{ $t('RiskDetails.Probability') }}
              </v-col>
              <v-col class="d-flex justify-end align-end mt-4 mr-4">
                {{ MatchTItle }}
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="9">
            <v-row no-gutters justify="center" align="center">
              <p class="mt-8">{{ $util.convertConsequenceToText(squareData.data) }} {{ squareData.data }}</p>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'MatrixSqaure',
  data: () => ({
    show: false,
    squareData: null,
    MatchOption: null,
  }),
  computed: {
    MatchTItle() {
      const MatchOptionNumber = this.MatchOption;
      const t = this.$t.bind(this);
      switch (MatchOptionNumber) {
        case 0:
          return t('RiskListHeaders.TimePlan');
        case 1:
          return t('RiskListHeaders.Expenses');
        case 2:
          return t('RiskListHeaders.Scope');
        case 3:
          return t('RiskListHeaders.Resources');
        case 4:
          return t('RiskDetails.Consequence');
        default:
          return t('RiskDetails.Consequence');
      }
    },
  },
  methods: {
    GetClass(value) {
      if (value == 0) {
        return 'GreenBox';
      } else if (value == 1) {
        return 'YellowBox';
      } else {
        return 'RedBox';
      }
    },
    writeName(value) {
      let name;
      if (value.commitStatus == 2) {
        name = `N ${value.riskNumber} ${value.title}`;
      } else if (value.commitStatus == 3) {
        name = `O ${value.riskNumber} ${value.title}`;
      }

      return name;
    },
    showDialog(_squareData, _MatchOption) {
      this.squareData = _squareData;
      this.MatchOption = _MatchOption;
      this.show = true;
    },
  },
};
</script>

<style>
.GreenBox {
  background-color: #12e59d;
  min-height: 110px;
}

.YellowBox {
  background-color: #fb8c00;
  min-height: 110px;
}

.RedBox {
  background-color: #ff5252;
  min-height: 110px;
}
</style>