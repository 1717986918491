<template>
  <v-container fluid v-if="project" class="py-0">
    <!-- <r-card>
      <template #header> -->
    <v-row no-gutters>
      <v-row no-gutters align="center">
        <v-col cols="3" class="mb-2 mb-md-0 d-flex align-center">
          <h2>{{ $t('PageTitles.Matrix') }}</h2>
          <v-checkbox v-model="disableRiskHistory" class="ml-4" :label="$t('MatrixHeaders.ShowNoRiskHistory')" />
        </v-col>

        <v-col cols="1">
          {{ MatchTItle }}
        </v-col>

        <v-col cols="4" class="mb-2 mb-md-0">
          <v-text-field hide-details outlined dense v-model="MatrixSearch" background-color="searchField" label="Search" />
        </v-col>
        <v-col cols="4" align="right" v-if="!isFullScreenProp">
          <v-btn :color="$vuetify.theme.dark ? 'black' : 'white'" depressed @click="OpenFullScreen">
            <v-icon left>mdi-arrow-expand-all</v-icon>
            {{ $t('ButtonsLabel.FullScreenMatrix') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-row>

    <v-row no-gutters justify="center" align="center">
      <v-col cols="3" justify="center">
        <v-select outlined hide-details :items="riskListItems" dense item-text="text" item-value="id" v-model="MatchOption"> </v-select>
      </v-col>

      <v-col cols="9" align="right" v-if="HasFullAccessPermissions">
        <v-btn :color="$vuetify.theme.dark ? 'black' : 'white'" depressed v-if="isManager" @click="updateRiskAppetite">
          <v-icon left>mdi-content-save</v-icon>
          {{ $t('ButtonsLabel.SaveRiskAppetite') }}
        </v-btn>
      </v-col>
    </v-row>

    <v-row class="mt-0">
      <v-col cols="11">
        <v-simple-table class="pa-0">
          <tbody>
            <tr v-for="(row, i) in MatrixCreation" :key="i">
              <td v-for="(item, j) in row" :key="j" style="width: 20vh; padding: 1px">
                <p v-if="item.text !== ''" class="pl-4">{{ item.text }}</p>
                <div v-if="item.text === ''" :class="GetClass(item.matrixItemLevel)" style="overflow-y: auto" class="aRedBox fill-height pa-0" id="style-1">
                  <v-row no-gutters>
                    <v-col cols="12" class="pt-0 pl-0" v-if="item.risks.length > 0">
                      <v-col class="d-flex flex-row justify-end pa-0">
                        <v-btn
                          class="justify-end"
                          :class="item.risks.length > 4 ? 'mr-1' : 'mr-5'"
                          style="position: absolute"
                          icon
                          title="See details"
                          @click="ShowSquare(item)"
                        >
                          <v-icon color="black">mdi-arrow-expand-all</v-icon>
                        </v-btn>
                      </v-col>
                      <v-col cols="10" v-for="(risks, k) in item.risks" :key="k" class="pa-0">
                        <v-btn
                          style="border-bottom: 1px solid primary; height: 26px"
                          color="black"
                          class="ma-0 px-2"
                          plain
                          :to="{ name: 'RiskVersionDetails', params: { historyId: risks.id } }"
                        >
                          {{ writeName(risks) }}
                        </v-btn>
                      </v-col>
                    </v-col>
                  </v-row>
                </div>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr class="pt-4">
              <td class="pl-4 mt-4">
                <v-col cols="" class="d-flex flex-colum">
                  <v-row no-gutters>
                    <v-col cols="12" class="d-flex justify-start">
                      {{ $t('RiskDetails.Probability') }}
                    </v-col>
                    <v-col class="d-flex justify-end align-end mt-4">
                      {{ MatchTItle }}
                    </v-col>
                  </v-row>
                </v-col>
              </td>
              <td class="pa-1" style="text-align: center">
                <p>{{ $t('MatrixHeaders.One') }}</p>
              </td>
              <td class="pa-1" style="text-align: center">
                <p>{{ $t('MatrixHeaders.Two') }}</p>
              </td>
              <td class="pa-1" style="text-align: center">
                <p>{{ $t('MatrixHeaders.Three') }}</p>
              </td>
              <td class="pa-1" style="text-align: center">
                <p>{{ $t('MatrixHeaders.Four') }}</p>
              </td>
              <td class="pa-1" style="text-align: center">
                <p>{{ $t('MatrixHeaders.Five') }}</p>
              </td>
            </tr>
          </tfoot>
        </v-simple-table>
      </v-col>

      <v-col cols="auto" class="my-auto">
        <v-slider
          class="mb-12"
          :color="project.riskAppetite < 1 ? 'error' : project.riskAppetite > 1 ? 'success' : 'warning'"
          v-model="project.riskAppetite"
          hide-details
          :tick-labels="[$t('RiskAppetite.Low'), $t('RiskAppetite.Standard'), $t('RiskAppetite.High')]"
          :min="0"
          :max="2"
          vertical
          ticks="always"
        >
        </v-slider>
      </v-col>
    </v-row>
    <matrix-full-screen ref="MatrixFullScreenRef"></matrix-full-screen>
    <matrix-square-full-screen ref="MatrixSquareFullScrenRef"></matrix-square-full-screen>
    <!-- </r-card> -->
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import apiService from '@/services/apiService.js';
import _ from 'lodash';
import MatrixFullScreen from './Dialogs/MatrixFullScreen.vue';
import MatrixSquareFullScreen from './Dialogs/MatrixSquareFullScreen.vue';
export default {
  name: 'Matrix',
  components: {
    MatrixFullScreen,
    MatrixSquareFullScreen,
  },
  props: {
    isFullScreenProp: Boolean,
    matrixDataProp: Object,
    projectProp: Object,
    riskListProp: Array,
  },
  data: () => ({
    MatchOption: 4,
    matrixData: {},
    project: {},
    riskList: [],
    selectvalue: null,
    loading: false,
    disableRiskHistory: false,
    MatrixSearch: '',
    labels: ['Lav', 'Standard ', 'Høj'],
  }),
  computed: {
    ...mapGetters(['currentProjectId', 'isManager', 'darkMode', 'user', 'projectList']),
    RiskFilter() {
      const riskHistoryDisabled = this.disableRiskHistory;
      let risks;
      if (riskHistoryDisabled == false) {
        risks = this.riskList;
      } else {
        risks = this.riskList.filter((item) => item.commitStatus == 2);
      }

      const search = this.MatrixSearch.toLowerCase();
      if (search === '') {
        return risks;
      } else if (search.includes('#n')) {
        let filter = risks.filter((item) => item.commitStatus == 2);
        if (search == '#n') {
          return filter;
        } else {
          let extraSearch = search.replace('#n ', '').toLowerCase();
          return filter.filter((item) => item.riskNumber.toString().includes(extraSearch) || item.title.toLowerCase().includes(extraSearch));
        }
      } else if (search.includes('#o')) {
        let filter = risks.filter((item) => item.commitStatus == 3);
        if (search == '#o') {
          return filter;
        } else {
          let extraSearch = search.replace('#o ', '').toLowerCase();
          return filter.filter((item) => item.riskNumber.toString().includes(extraSearch) || item.title.toLowerCase().includes(extraSearch));
        }
      } else {
        return risks.filter((item) => item.riskNumber.toString().includes(search) || item.title.toLowerCase().includes(search));
      }
    },
    MatchTItle() {
      const MatchOptionNumber = this.MatchOption;
      const t = this.$t.bind(this);
      switch (MatchOptionNumber) {
        case 0:
          return t('RiskListHeaders.TimePlan');
        case 1:
          return t('RiskListHeaders.Expenses');
        case 2:
          return t('RiskListHeaders.Scope');
        case 3:
          return t('RiskListHeaders.Resources');
        case 4:
          return t('RiskDetails.Consequence');
        default:
          return t('RiskDetails.Consequence');
      }
    },
    MatrixColorHandler() {
      const option = this.project.riskAppetite;
      const newMatrix = this.MatrixColorChanger(option);
      return newMatrix;
    },

    MatrixCreation() {
      const matrixData = this.MatrixColorHandler;

      if (matrixData != undefined) {
        const allRisks = this.RiskFilter.map((x) => ({
          id: x.id,
          riskNumber: x.riskNumber,
          commitStatus: x.commitStatus,
          probability: x.probability,
          timeLine: x.timeLine,
          expenses: x.expenses,
          scope: x.scope,
          resource: x.resource,
          title: x.title,
          riskOriginalId: x.riskOriginalId,
          overallConsequese: this.$util.consequenceCalculator(x.timeLine, x.expenses, x.scope, x.resource, x.probability),
        }));
        const matrix = matrixData.map((x) => ({
          text: '',
          data: x.consequence,
          probability: x.probability,
          matrixItemLevel: x.matrixItemLevel,
          id: x.id,
          risks: [],
        }));
        matrix.forEach((MatrixItem) => {
          const risksToAdd = allRisks.filter((x) => x.probability == MatrixItem.probability && this.setData(x) == MatrixItem.data);
          MatrixItem.risks = risksToAdd;
        });
        const chunk = _.chunk(matrix, 5);
        chunk.forEach((row) => {
          const first = row[0].probability;
          const text = this.$util.convertConsequenceToText(first);
          const textItem = {
            text: `${text}  ${first}`,
          };
          row.unshift(textItem);
        });
        return chunk;
      }

      return [];
    },
    riskListItems() {
      const t = this.$t.bind(this);
      return [
        {
          text: t('RiskListHeaders.TimePlan'),
          id: 0,
        },
        {
          text: t('RiskListHeaders.Expenses'),
          id: 1,
        },
        {
          text: t('RiskListHeaders.Scope'),
          id: 2,
        },
        {
          text: t('RiskListHeaders.Resources'),
          id: 3,
        },
        {
          text: t('RiskDetails.Consequence'),
          id: 4,
        },
      ];
    },
    HasFullAccessPermissions() {
      if (this.isAdmin) {
        return true;
      }
      if (this.$util.HasFullAccess(this.user, this.project.permissions) == false) {
        return false;
      } else if (this.AdminOrIsInSameCompany) {
        if (this.isManager) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
  },
  methods: {
    GetClass(value) {
      if (value == 0) {
        return 'GreenBox';
      } else if (value == 1) {
        return 'YellowBox';
      } else {
        return 'RedBox';
      }
    },
    setData(value) {
      switch (this.MatchOption) {
        case 0:
          return value.timeLine;
        case 1:
          return value.expenses;
        case 2:
          return value.scope;
        case 3:
          return value.resource;
        case 4:
          return value.overallConsequese;
      }
    },
    ShowSquare(Data) {
      this.$refs.MatrixSquareFullScrenRef.showDialog(Data, this.MatchOption);
    },
    MatrixColorChanger(option) {
      if (this.matrixData.matrixItems !== undefined) {
        const copy = _.cloneDeep(this.matrixData.matrixItems);
        switch (option) {
          case 0:
            copy.find((x) => x.probability == 5 && x.consequence == 1).matrixItemLevel = 2;
            copy.find((x) => x.probability == 5 && x.consequence == 2).matrixItemLevel = 2;
            copy.find((x) => x.probability == 5 && x.consequence == 3).matrixItemLevel = 2;
            copy.find((x) => x.probability == 4 && x.consequence == 1).matrixItemLevel = 1;
            copy.find((x) => x.probability == 3 && x.consequence == 1).matrixItemLevel = 1;
            copy.find((x) => x.probability == 2 && x.consequence == 2).matrixItemLevel = 1;
            copy.find((x) => x.probability == 1 && x.consequence == 3).matrixItemLevel = 1;
            copy.find((x) => x.probability == 1 && x.consequence == 4).matrixItemLevel = 1;
            copy.find((x) => x.probability == 1 && x.consequence == 5).matrixItemLevel = 2;
            return copy;
          case 1:
            return copy;
          case 2:
            copy.find((x) => x.probability == 5 && x.consequence == 2).matrixItemLevel = 1;
            copy.find((x) => x.probability == 5 && x.consequence == 3).matrixItemLevel = 1;
            copy.find((x) => x.probability == 4 && x.consequence == 3).matrixItemLevel = 1;
            copy.find((x) => x.probability == 4 && x.consequence == 4).matrixItemLevel = 1;
            copy.find((x) => x.probability == 3 && x.consequence == 2).matrixItemLevel = 0;
            copy.find((x) => x.probability == 3 && x.consequence == 4).matrixItemLevel = 1;
            copy.find((x) => x.probability == 3 && x.consequence == 5).matrixItemLevel = 1;
            copy.find((x) => x.probability == 2 && x.consequence == 3).matrixItemLevel = 0;
            copy.find((x) => x.probability == 2 && x.consequence == 4).matrixItemLevel = 1;
            copy.find((x) => x.probability == 2 && x.consequence == 5).matrixItemLevel = 1;
            return copy;
          default:
            return this.matrixData.matrixItems;
        }
      } else return null;
    },
    writeName(value) {
      let name;
      if (value.commitStatus == 2) {
        name = `#N ${value.riskNumber} ${value.title}`;
      } else if (value.commitStatus == 3) {
        name = `#O ${value.riskNumber} ${value.title}`;
      }

      if (name.length > 18) {
        const arr = name.match(/.{1,18}(\s|$)/g);
        let replacement = '...';
        const newName = arr[0].slice(0, -3) + replacement;
        return newName;
      } else {
        return name;
      }
    },

    async getProject() {
      if (this.isFullScreenProp === false) {
        this.loading = true;
        this.matrixData = await apiService.getMatrix(this.currentProjectId);
        this.riskList = await apiService.getLatestTwoRisks(this.currentProjectId);
        this.project = await apiService.getProjectById(this.currentProjectId);
        this.IsAdminOrIsInSameCompany();
        this.loading = false;
      }
    },
    async updateRiskAppetite() {
      const data = {
        ProjectId: this.project.id,
        riskAppetite: this.project.riskAppetite,
      };
      await apiService.updateAppetite(data);
    },
    async IsAdminOrIsInSameCompany() {
      if (this.isAdmin) {
        this.AdminOrIsInSameCompany = true;
      } else if (this.project.companyId != null) {
        if (this.project.companyId == this.user.companyId) {
          this.AdminOrIsInSameCompany = true;
        } else {
          this.AdminOrIsInSameCompany = false;
        }
      } else {
        this.AdminOrIsInSameCompany = false;
      }
    },
    OpenFullScreen() {
      this.$refs.MatrixFullScreenRef.showFullScreen(this.matrixData, this.project, this.riskList);
    },
  },
  created() {
    if (this.isFullScreenProp == false) {
      this.getProject();
    } else {
      this.matrixData = this.matrixDataProp;
      this.project = this.projectProp;
      this.riskList = this.riskListProp;
    }
  },
};
</script>

<style>
.GreenBox {
  background-color: #49bd55;
  min-height: 110px;
}

.YellowBox {
  background-color: #fff400;
  min-height: 110px;
}

.RedBox {
  background-color: #ed6351;
  min-height: 110px;
}

#style-1::-webkit-scrollbar {
  width: 16px;
}

#style-1::-webkit-scrollbar-thumb {
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
  background-color: #aaaaaa;
}
</style>

<style lang="scss">
tbody {
  tr:hover {
    background-color: transparent !important;
  }
}
</style>